import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { InspectionReportsServices } from './InspectionReports.services';
import * as constants from '../../../constants';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { Link } from "react-router-dom";
import { commonServices } from '../../../../app/commonServices/commonServices';
import * as firebase from 'firebase/app';
import FilterComponent from '../../../partials/layout/FilterComponent';
import { Badge } from 'react-bootstrap';
import { store } from 'react-notifications-component';

export default class InspectionReports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isFetching: false,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      showCreateInspectionReportModal: false,
      published: ['YES'],
      requestedFilters: ['published'],
    }
  }

  /**
   * component did mount method
   */
  async componentDidMount() {
    await this.listReports();
    await this.getCities();
    await this.getPropertyManagers();
  }

  /**
   * Method to get all the payments data
   */
  listReports = async () => {
    this.setState({ isFetching: true });
    const { offset, limit, houseIdFromPropertyProfile, cityIds, propertyManagerId,
      minimumExecutionDate, maximumExecutionDate, houseId, published } = this.state;
    const data = {
      cityIds,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      minimumExecutionDate,
      maximumExecutionDate,
      houseId: houseIdFromPropertyProfile || houseId,
      published: !houseIdFromPropertyProfile ? (published && published[0]) : undefined,
      // houseId,
      include: houseIdFromPropertyProfile ? undefined : ['PROPERTY_MANAGER'],
      offset,
      limit,
    }
    const result = await InspectionReportsServices.listReports(data);
    if (result && result.status === 200) {
      const totalCount = result.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit);
      await this.setState({
        reports: result.data.houseInspectionReports,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      });
    }
    this.setState({ isFetching: false })
  }

  /**
* Method to get all the cities
*/
  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      this.setState({ formattedCities });
      preSelectedCities && this.setState({ preSelectedCities });
    }
  }

  /**
   * Method to get property managers
   */
  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  // handleCreateInspectionReportButtonClick = () => {
  //   this.setState(state => ({ showCreateInspectionReportModal: !state.showCreateInspectionReportModal }));
  // }

  /**
   * method to handle page change for pagination
   * @param {*} page
   */
  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.listReports();
  }

  /**
   * Method to handle data of filter component
   * @param {String} childData
   */
  handleFilterChildData = async (childData) => {
    if (childData) {
      await this.setState({ ...childData });
      await this.resetPage();
      await this.listReports();
      await this.getCities();
      await this.getPropertyManagers();
      await this.getSelectedProperty();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'Inspection Reports' });
    }
  }

  /**
   * Method to get seleted property
   */
  getSelectedProperty = async () => {
    const { houseId, propertyOptions } = this.state
    let selectedHouseObject = undefined;
    if (houseId && propertyOptions) {
      selectedHouseObject = propertyOptions.find(property => (property.id === houseId));
    }
    await this.setState({ selectedHouseObject })
  }

  /**
   * Method to reset page
   */
  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      //limit: 10,
    });
  }

  /**
   * method to get inspection report badge
   * @param {String} inspection_status 
   */
  getInspectionReportBadge = (isPublished) => {
    return isPublished === true ? (<Badge variant="success" title="Published">P</Badge>) : (<Badge variant="danger" title="Unpublished">U</Badge>)
  }

  handleSendReport = async (id) => {
    const result = await InspectionReportsServices.sendReport(id);
    if (result && result.status === 201) {
      store.addNotification({
        title: "Success!",
        message: `Report sent!`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
          pauseOnHover: true
        }
      });
    }
  }

  render() {
    const { isFetching, reports, page, total, totalCount, houseIdFromPropertyProfile,
      propertyManagers, propertyManagerId, formattedCities, preSelectedCities,
      minimumExecutionDate, maximumExecutionDate, selectedHouseObject, published,
      requestedFilters
    } = this.state;
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    return (
      <>
        <Container fluid>
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={`Inspection Reports (${totalCount || 0})`}
              toolbar={
                <PortletHeaderToolbar>
                  {/* {houseIdFromPropertyProfile && <Button className="mr-2" variant="link" size="sm" onClick={() => this.handleCreateInspectionReportButtonClick(houseIdFromPropertyProfile)}>Add Inspection Report</Button>} */}
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <FilterComponent
                requestedFilters={requestedFilters}
                formattedCities={!houseIdFromPropertyProfile ? formattedCities : undefined}
                preSelectedCities={!houseIdFromPropertyProfile ? preSelectedCities : undefined}
                minimumExecutionDate={minimumExecutionDate}
                maximumExecutionDate={maximumExecutionDate}
                selectedHouseObject={!houseIdFromPropertyProfile ? selectedHouseObject : undefined}
                selectHouse={!houseIdFromPropertyProfile ? true : undefined}
                propertyManagers={!houseIdFromPropertyProfile ? propertyManagers : undefined}
                propertyManagerId={!houseIdFromPropertyProfile ? propertyManagerId : undefined}
                published={!houseIdFromPropertyProfile ? published : undefined}
                recordsPerPage={constants.RECORDS_PER_PAGE}
                selectedRecordValue={this.state.limit}
                filterParentCallback={this.handleFilterChildData}
              />
              <Card>
                {reports && reports.length === 0 && <p>{constants.ZERO_DATA}</p>}
                {reports && reports.length > 0 && <Card.Header>
                  <Row className="azuro-table-header d-flex align-items-center px-3 py-2">
                    {!houseIdFromPropertyProfile && <Col md={3}><strong>Property</strong></Col>}
                    <Col md={houseIdFromPropertyProfile ? 4 : 3}><strong>Executor Name</strong></Col>
                    <Col md={houseIdFromPropertyProfile ? 4 : 2}><strong>Execution Date</strong></Col>
                    <Col md={3}><strong>Actions </strong></Col>
                  </Row>
                </Card.Header>}
                <Card.Body className="p-0">
                  {reports && reports.map(report =>
                    <>
                      <Row className="azuro-table-row d-flex align-items-center px-3 py-2">
                        {!houseIdFromPropertyProfile && <Col md={3} className="d-flex flex-row">
                          <span className="pr-3">
                            {this.getInspectionReportBadge(report?.isPublished)}
                          </span>
                          {report.house &&
                            <span>{report.house.formattedHouseName} <br />
                              <span className="small text-muted">{report.house.propertyManager ? `PM: ${(report.house.propertyManager && report.house.propertyManager.fullName)}` : `PM: Not Assigned`}</span>
                            </span>}
                        </Col>}
                        <Col md={houseIdFromPropertyProfile ? 4 : 3}>{report.executor && report.executor.fullName}</Col>
                        <Col md={houseIdFromPropertyProfile ? 4 : 2}>{report.executionDate}</Col>
                        <Col md={3}>
                          <>{!report.isPublished === true &&
                            <Link to={`/inspection-report/${report.id}/edit`}>
                              <Button variant="link" className='mr-2' size="sm">Edit Report</Button>
                            </Link>}
                            <Link to={`/inspection-report/${report.id}/view`}>
                              <Button variant="link" className='mr-2' size="sm">View Report</Button>
                            </Link>
                            {report.isPublished === true && <Button variant="link" className='mr-2' size="sm" onClick={() => { this.handleSendReport(report.id) }}>Send Report</Button>}
                          </>
                        </Col>

                        {report.house && <Col md={1}>
                          <Link to={`/property-profile/${report.house.id}`}>
                            <Button size='sm' variant='clean'><i className="flaticon-home-1"></i></Button>
                          </Link>
                        </Col>}

                      </Row>
                      <hr className="my-1" />
                    </>
                  )
                  }
                </Card.Body>
              </Card>
              {/* Code for pagination */}
              {showPagination && showPagination && <UltimatePagination
                currentPage={page}
                totalPages={total}
                onChange={this.onPageChange}
                boundaryPagesRange={0}
                hidePreviousAndNextPageLinks={true}
                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
              />}
            </PortletBody>
          </Portlet>
        </Container>

        {/* <Modal backdrop="static" keyboard={false} key={'m' + 1} show={this.state.showCreateInspectionReportModal} onHide={this.handleCreateInspectionReportButtonClick}>
          <Modal.Header>
            <Modal.Title>Create Inspection Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                houseId: houseIdFromPropertyProfile
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await InspectionReportsServices.createReport(values);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Inspection Report created!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showCreateInspectionReportModal: !state.showCreateInspectionReportModal }));
                    await this.listReports();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <p><strong>Confirm create of Inspection Report?</strong></p>
                      </Col>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleCreateInspectionReportButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Creating...' : 'Yes'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal> */}
      </>
    )
  }

}

